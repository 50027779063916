<template>
  <div class="blog">
    <img :src="post.cover" class="cover" />
    <div class="container">
      <div class="text-center">
        <h1 class="headline">{{ post.title }}</h1>
        <p>
          Posted on: {{ `${post.date[0]}/${post.date[1]}/${post.date[2]}` }}
        </p>
        <div class="center">
          <Avatar />
          <span class="margin">
            {{ config.username }}
          </span>
        </div>
      </div>
      <hr>
      <router-view class="post"></router-view>
      <PostTags :postTags="post.tags" />
      <PostNavBtn :prev="prevPost" :next="nextPost" />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import PostNavBtn from '@/components/PostNavBtn.vue'
import PostTags from '@/components/PostTags.vue'

export default {
  name: 'ViewPost',
  components: {
    Avatar,
    PostNavBtn,
    PostTags
  },
  data: function () {
    return {
      postId: this.$route.path.split('posts/')[1], // postId passed after the /
      posts: this.getConfig('posts.json').posts,
      post: {
        title: null,
        date: [
          null,
          null,
          null
        ],
        tags: []
      },
      config: this.getConfig().config,
      prevPost: {
        title: null,
        id: null
      },
      nextPost: {
        title: null,
        id: null
      }
    }
  },
  methods: {
    getPost: function () {
      // because of markdown file rendering, can't use the triditional dynamic route matching
      this.postId = this.$route.path.split('posts/')[1]
      const curPostIdx = this.posts.findIndex((post) => post.id === this.postId)
      if (curPostIdx >= 0) { // post found
        this.post = this.posts[curPostIdx]
        this.changeTitle(this.post.title)
        try {
          if (curPostIdx > 0) { // has a previous post (not the first one)
            this.nextPost.title = this.posts[curPostIdx - 1].title
            this.nextPost.id = this.posts[curPostIdx - 1].id
          } else {
            this.nextPost.id = null
          }
          if (curPostIdx < this.posts.length - 1) { // has a next post (not the last one)
            this.prevPost.title = this.posts[curPostIdx + 1].title
            this.prevPost.id = this.posts[curPostIdx + 1].id
          } else {
            this.prevPost.id = null
          }
        } catch (err) {
          console.log(err) // Handle error
        }
      }
    }
  },
  mounted: function () {
    this.getPost()
  },
  watch: {
    $route (to, from) {
      this.getPost()
    }
  }
}
</script>

<style>
.cover {
  width: 100vw;
}

.margin {
  margin-left: 20px;
}

.post img {  /* limit image max width to 100vw in a post */
  max-width: 100%;
}

.blog {
  /*max-width: 50vw;*/
  /*margin: 10rem auto;*/
  /deep/ {
    h1 {
      font-size: 3rem;
      margin-bottom: .2rem;
      color: #42b883;
    }
    h4 {
      margin-bottom: 3rem;
      color: #35495e;
    }
  }
}

    .tabbed {
      overflow-x: hidden; /* so we could easily hide the radio inputs */
      margin: 32px 0;
      padding-bottom: 16px;
      border-bottom: 1px solid #ccc;
    }

    .tabbed [type="radio"] {
      /* hiding the inputs */
      display: none;
    }

    .tabs {
      display: flex;
      align-items: stretch;
      list-style: none;
      padding: 0;
      border-bottom: 1px solid #ccc;
    }
    .tab > label {
      display: block;
      margin-bottom: -1px;
      padding: 12px 15px;
      border: 1px solid #ccc;
      background: #eee;
      color: #666;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      transition: all 0.3s;
    }
    .tab:hover label {
      border-top-color: #333;
      color: #333;
    }

    .tab-content {
      display: none;
      color: #777;
    }

    /* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
    .tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
    .tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
    .tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
    .tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
    .tabbed [type="radio"]:nth-of-type(5):checked ~ .tabs .tab:nth-of-type(5) label {
      border-bottom-color: #fff;
      border-top-color: #B721FF;
      background: #fff;
      color: #222;
    }

    .tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
    .tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
    .tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
    .tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
      display: block;
    }
</style>
